export const optionsRowFirst = [
  {
    to: 'https://www.ovo.com/terms-conditions/',
    text: 'Terms & Conditions',
    external: true,
  },
  {
    to: 'https://www.ovo.com/privacy-policy/',
    text: 'Privacy & Cookies Policy',
    external: true,
  },
  {
    to: 'https://www.ovo.com/gender-pay-statement/',
    text: 'Gender Pay Statement',
    external: true,
  },
  {
    to: 'https://ovo.com/basis-of-preparation/',
    text: '2018 Basis of Preparation',
    external: true,
  },
];

export const optionsRowSecond = [
  {
    to: 'https://www.ovo.com/modernslavery/',
    text: 'Modern Slavery Statement',
    external: true,
  },
  {
    to: 'https://www.ovo.com/sustainability-assurance-report/',
    text: 'Sustainability Assurance Report',
    external: true,
  },
  {
    to: '/accessibility-at-ovo',
    text: 'Accessibility',
    external: false, 
  },
];
