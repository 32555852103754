import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

function Spacer(props) {
  const { children } = props;
  return <Box mt={[4, 6]}>{children}</Box>;
}

Spacer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Spacer;
