const styles = ({ palette, typography, spacing }) => ({
  menuContainer: {
    display: 'flex',
    padding: '0',
    margin: '0',
  },
  menuItem: {
    listStyle: 'none',
    padding: spacing(0, 1),
    '&:last-of-type': {
      paddingRight: '0px',
    },
  },
  navLink: {
    fontSize: typography.pxToRem(16),
    fontWeight: '700',
    color: palette.text.primary,
    textDecoration: 'none',
    transition: 'all .3s ease-out',
    opacity: 1,
    '&:hover': {
      color: palette.text.green,
      opacity: 0.8,
    },
  },
  navLinkActive: {
    borderBottom: `2px solid ${palette.background.green}`,
    paddingBottom: '5px',
  },
});

export default styles;
