import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Consumer } from 'store/createContext';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import linkResolver from 'utils/linkResolver';
import styles from './style';

MobileNavigation.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.array,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

const Query = graphql`
  query MobileMenuNavigationQuery {
    allPrismicGlobalLayout {
      edges {
        node {
          data {
            main_menu {
              menu_label {
                text
                type
              }
              link {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

function MobileNavigation(props) {
  const { classes } = props;
  return (
    <React.Fragment>
      <Consumer>
        {({ toggleMenu }) => (
          <StaticQuery
            query={`${Query}`}
            render={data => {
              return (
                <div className={classes.menuContainer}>
                  <Container maxWidth="lg">
                    {data.allPrismicGlobalLayout.edges[0].node.data.main_menu.map(
                      (link, i) => (
                        <div key={i} className={classes.menuItem}>
                          <Link
                            to={`/${
                              link.link.uid != null ? link.link.uid : ''
                            }`}
                            className={classes.navLink}
                            activeClassName={classes.navLinkActive}
                            exact={'true'}
                            onClick={toggleMenu}
                          >
                            <span>{link.menu_label[0].text}</span>
                          </Link>
                        </div>
                      )
                    )}
                  </Container>
                </div>
              );
            }}
          />
        )}
      </Consumer>
    </React.Fragment>
  );
}

export default withStyles(styles)(MobileNavigation);
