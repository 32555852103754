import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';
import { withStyles } from '@material-ui/styles';
import withWidth from '@material-ui/core/withWidth';
import { compose, isTabletView } from 'utils';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Avatar from 'components/avatar';
import Navigation from 'components/navigation';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import styles from './style';
import MobileNavigation from 'components/navigation/mobile';

Header.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.string,
  disableHeadroom: PropTypes.bool,
  headerOpacity: PropTypes.string,
};

function Header(props) {
  const { classes, width, disableHeadroom, headerOpacity } = props;
  const disable = true;
  return (
    <Headroom
      disable={disable}
      style={{
        zIndex: '5',
        WebkitTransition: 'all 0.2s ease-in-out 0s',
        MozTransition: 'all 0.2s ease-in-out 0s',
        OTransition: 'all 0.2s ease-in-out 0s',
        transition: 'all 0.2s ease-in-out 0s',
        position: disable ? 'relative' : 'fixed',
      }}
    >
      <Consumer>
        {({ menuOpen }) => (
          <div
            className={`offCanvusMenuWrapper ${menuOpen ? 'open' : 'closed'}`}
          >
            <MobileNavigation />
          </div>
        )}
      </Consumer>

      <Box
        component="header"
        bgcolor="background.default"
        py={0.5}
        className={classes.header}
      >
        <Container maxWidth="lg">
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.gridContainer}
          >
            <Grid item className={classes.item1}>
              <Link to="/" className={classes.logo}>
                <Avatar />
              </Link>
            </Grid>
            <Grid item className={classes.item3}>
              <Navigation />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Headroom>
  );
}

export default compose(withWidth(), withStyles(styles))(Header);
