const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    zIndex: 10,
  },
  toggleBtn: {
    minWidth: '35px',
    border: '0px',
    background: 'none',
    padding: '0px',
    textAlign: 'right',
  },
  // In styles.js or wherever you define styles
  link: {
    marginRight: '.85rem',
    color: '#00261C', // Or whatever color you prefer
    textDecoration: 'none',
    fontSize: '13px',
    transition: 'fill 0.2s ease-in-out',
    '&:hover': {
      '& svg path': {
        fill: '#044d29',
      },
    },
    '& svg path': {
      fill: '#0a9828',
      '&:hover': {
        fill: '#044d29',
      },
    },
  },
});

export default styles;
