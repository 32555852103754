import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import { withStyles } from '@material-ui/styles';
import withWidth from '@material-ui/core/withWidth';
import { compose, isTabletView } from 'utils';
import MainNavigation from './main';
import options from './content';
import styles from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

Navigation.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.string,
};

function Navigation(props) {
  const { classes, width } = props;
  return (
    <div className={classes.container}>
      {isTabletView(width) ? (
        <Consumer>
          {({ toggleMenu, menuOpen }) => (
            <button
              onClick={toggleMenu}
              className={classes.toggleBtn}
              aria-label="Toggle menu"
            >
              {menuOpen ? (
                <FontAwesomeIcon icon={faTimes} size="2x" />
              ) : (
                <FontAwesomeIcon icon={faBars} size="2x" />
              )}
            </button>
          )}
        </Consumer>
      ) : (
        <MainNavigation options={options} />
      )}
    </div>
  );
}

export default compose(withWidth(), withStyles(styles))(Navigation);
