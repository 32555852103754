const styles = ({ palette, typography }) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  menuContainer: {
    position: 'relative',
    zIndex: '1',
    width: '100%',
    background: '#fff',
    textAlign: 'left',
    paddingTop: typography.pxToRem(16),
    paddingLeft: '0',
    paddingRight: '0',
    paddingBottom: '0',
    borderBottom: '1px solid rgb(207, 213, 219)',
  },
  menuItem: {
    borderBottom: '1px solid rgb(241, 246, 248)',
    '&:last-of-type': {
      marginBottom: `${typography.pxToRem(20)}`,
    },
  },
  navLink: {
    width: '100%',
    border: 'none',
    display: 'inline-block',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: palette.text.primary,
    '& span': {
      padding: '0.8rem',
      display: 'block',
    },
  },
  navLinkActive: {
    fontWeight: 500,
    '& span': {
      borderLeft: `4px solid ${palette.text.green}`,
    },
  },
});

export default styles;
