import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { optionsRowFirst, optionsRowSecond } from './menu';
import Spacer from 'components/spacer';

import GlassDoorLogo from 'images/glassdoor-badge.png';
import AwardProudScotland from 'images/proud_scotland_logo.png';
import AwardNationalDiversity from 'images/national_diversity_awards.png';
import palette from '../../styles/palette';

import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  LgreyText: {
    color: theme.palette.text.black,
  },
  footerMenu: {
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  footerMenuItem: {
    display: 'inline-block',
    listStyle: 'none',
    padding: '0',
    marginBottom: '20px',
    textAlign: 'left',
  },
  footerMenuItemLink: {
    color: theme.palette.text.secondary,
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      color: theme.palette.text.black,
    },
  },
  GlassDoorLogo: {
    width: '60%',
    margin: '0 auto',
    display: 'block',
  },
});

function Footer(props) {
  const { classes } = props;
  return (
    <Box bgcolor="background.green" component="footer" py={6}>
      <Container maxWidth="lg">
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item xs={8}>
            <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
              <Box component="ul" className={classes.footerMenu}>
                {optionsRowFirst.map((link, i) => (
                  <Box
                    className={classes.footerMenuItem}
                    component="li"
                    key={i}
                  >
                    {link.external ? (
                      <a
                        href={link.to}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.footerMenuItemLink}
                      >
                        <Typography component="span" variant="body1">
                          {link.text}
                        </Typography>
                      </a>
                    ) : (
                      <Link to={link.to} className={classes.footerMenuItemLink}>
                        <Typography component="span" variant="body1">
                          {link.text}
                        </Typography>
                      </Link>
                    )}
                  </Box>
                ))}
              </Box>
              <Box
                component="ul"
                className={classes.footerMenu}
                ml={[0, 0, 5, 10]}
              >
                {optionsRowSecond.map((link, i) => (
                  <Box
                    className={classes.footerMenuItem}
                    component="li"
                    key={i}
                  >
                    {link.external ? (
                      <a
                        href={link.to}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.footerMenuItemLink}
                      >
                        <Typography component="span" variant="body1">
                          {link.text}
                        </Typography>
                      </a>
                    ) : (
                      <Link to={link.to} className={classes.footerMenuItemLink}>
                        <Typography component="span" variant="body1">
                          {link.text}
                        </Typography>
                      </Link>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container>
              <Grid item xs={12} md={5}>
                <Typography component="p" variant="body1" color="textSecondary">
                  Want to hear what others think?
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid container style={{ alignItems: 'center' }}>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <a
                      href="https://www.glassdoor.co.uk/Reviews/OVO-Reviews-E767881.htm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={GlassDoorLogo}
                        alt="Check out OVO on Glassdoor"
                        style={{ width: '100%' }}
                      />
                    </a>
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      src={AwardProudScotland}
                      alt="Scotland Award"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <img
                      src={AwardNationalDiversity}
                      alt="Diversity"
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box style={{ borderTop: '1px solid #E5E5E5' }} pt={4} mt={4}>
          <Typography variant="body1" component="p" color="textSecondary">
            OVO, registered office, 1 Rivergate Temple Quay Bristol, BS1 6ED,
            Company no. 08862063, Registered in England and Wales &copy; 2021 -
            OVO Group. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Footer);
