import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    html, body {
        overflow-x: hidden;
    }
    h1, h2, h3, h4, h5, h6 {
        font-size: inherit;
        margin-block-start: 0em;
        margin-block-end: 0em;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    .offCanvusMenuWrapper {
        transition: all 0.2s ease-in-out;
        &.closed {
            max-height: 0px;
            overflow: hidden;
        }
        &.open {
            max-height: 400px;
        }
    }
    .headroom {
        box-shadow: rgb(230, 230, 230) 1px 2px 10px -5px;
    }
    .headroom-wrapper {
        width: 100%;
        z-index: 10;
        top: 0;
        @media only screen and (min-width: 960px) {
            height: 0 !important;
            position: fixed;
        }
    }
    .externalTextLink {
        border-bottom: 1px solid #0A9828;
    }
    .jobDetailsEmbedded a {
        color: #0A9828;
        font-weight: 700 !important;
        text-decoration: underline;
    }
    .jobDetailsEmbedded a span {
        font-weight: 700 !important;
    }
    .aria-hidden {
        display: none;
    }
    .headroom {
        transition: all 0.2s ease-in-out 0s;
    }
    .headroom--pinned header {
        opacity: 1 !important;
    }
    .RichTextBlocklink {
        color: #FFF;
        display: inline-block;
        padding: 10px 20px;
        transition: all 0.2s ease-in-out;
        border-radius: 5px;
        background-color: #044D29;
        margin-top: 1rem;
        font-weight: 800;
        &:hover {
            background-color: #044D29;
            box-shadow: 0px 4px 8px 2px rgba(0,0,0,0.1);
        }
    }
    .w-100 {
        width: 100%;
    }
    .rich-text-hyperlink {
        text-decoration: underline;
        color: #0A9828;
    }
    .pink-btn {
        color: #FFF;
        display: inline-block;
        padding: 10px 20px;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        border-radius: 5px;
        background-color: #044D29;
        margin-top: 1 rem;
        font-weight: 800;
    }
    .white-btn {
        color: #044D29;
        display: inline-block;
        padding: 10px 20px;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
        border-radius: 5px;
        background-color: #fff;
        margin-top: 1 rem;
        font-weight: 800;
        border: 1px solid #044D29;
    }
    .pink-circle-text {
        background-image: url('images/pink-circle.svg');
    }
    .text-left-image-right .rich-text-list {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .text-left-image-right .rich-text-list li {
        margin-right: 1rem;
    }
    .rec-carousel-item {
        display: flex;
        align-items: center;
    }
    .rec-dot {
        box-shadow: none !important;
        border: 1px solid #f3f3f3 !important;
        background: #f3f3f3 !important;
        height: 15px !important;
        width: 15px !important;
    }
    .rec-dot_active {
        box-shadow: none !important;
        border: 1px solid #191919 !important;
        background: #191919 !important;
        height: 15px !important;
        width: 15px !important;
    }
    .modal-content .block-img{
        text-align: center;
        margin-top: 30px;
        display: inline-block;
        max-width: 30%;
    }
    .modal-content .block-img img {
        max-width: 100%;
    }
    .swiper-wrapper {
        align-items: center;
    }
    #onetrust-policy-text {
        font-family: OVOCircular Book,Futura,Century Gothic,sans-serif !important;
    }
    #onetrust-policy-text p {
        font-family: OVOCircular Book,Futura,Century Gothic,sans-serif !important;
    }
    #onetrust-policy-text a {
        color: #0a9828 !important;
    }

`;
