const styles = theme => ({
  container: {
    width: '100%',
  },
  header: {
    zIndex: '10',
    position: 'relative',
    boxShadow: '0px -8px 8px 0px rgb(241, 246, 248)',
  },
  logo: {
    border: 'none',
    transition: 'all .3s',
    textDecoration: 'none',
    display: 'block',
  },
  brandTitle: {
    textTransform: 'capitalize',
    color: theme.palette.text.green,
  },
  gridContainer: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  item1: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      order: 2,
      flex: '0 0 33.333333%',
      maxWidth: '33.333333%',
    },
  },
  item2: {
    order: 2,
    paddingLeft: '1em',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      paddingLeft: '0em',
      flex: '0 0 33.333333%',
      maxWidth: '33.333333%',
    },
  },
  item3: {
    order: 3,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      flex: '0 0 33.333333%',
      maxWidth: '33.333333%',
    },
  },
});

export default styles;
