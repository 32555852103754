import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';

import styles from './style';

import linkResolver from 'utils/linkResolver';

MainNavigation.propTypes = {
  classes: PropTypes.object,
};

const Query = graphql`
  query MenuNavigationQuery {
    allPrismicGlobalLayout {
      edges {
        node {
          data {
            main_menu {
              menu_label {
                text
                type
              }
              link {
                uid
              }
            }
          }
        }
      }
    }
  }
`;

function MainNavigation(props) {
  const { classes } = props;
  return (
    <StaticQuery
      query={`${Query}`}
      render={data => {
        return (
          <ul className={classes.menuContainer}>
            {data.allPrismicGlobalLayout.edges[0].node.data.main_menu.map(
              (link, i) => (
                <Typography component="li" className={classes.menuItem} key={i}>
                  <Link
                    to={`/${link.link.uid != null ? link.link.uid : ''}`}
                    className={classes.navLink}
                    activeClassName={classes.navLinkActive}
                    exact={'true'}
                  >
                    <span>{link.menu_label[0].text}</span>
                  </Link>
                </Typography>
              )
            )}
          </ul>
        );
      }}
    />
  );
}

export default withStyles(styles)(MainNavigation);
