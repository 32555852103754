import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import theme from 'styles/theme';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';

import 'normalize.css';

import GlobalStyle from 'global.css.js';

const Layout = ({ headerOpacity, children }) => (
  <React.Fragment>
    <GlobalStyle />
    <Head />
    <ThemeProvider theme={theme}>
      <Header headerOpacity={headerOpacity} />
      {children}
      <Footer />
    </ThemeProvider>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerOpacity: PropTypes.string,
};

export default Layout;
